import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from 'components/Layout'

import Hero from 'screens/Home/components/Hero'
import Benefits from 'screens/Home/components/Benefits'
import Flow from 'screens/Home/components/Flow'
import Summary from 'screens/Home/components/Summary'
import Comparison from 'screens/Home/components/Comparison'
import Faq from 'screens/Home/components/Faq'
import Testimonials from 'screens/Home/components/Testimonials'
import Regulations from 'screens/Home/components/Regulations'
import Blog from 'screens/Home/components/Blog'
import Map from 'screens/Home/components/Map'
import Partner from 'screens/Home/components/Partner'

import { useLocation, useTranslate } from 'context/location'

const Page = (props) => {
  const translate = useTranslate()
  const { countryCode } = useLocation()

  // If, at some point, we want to get featured articles from Contentful
  // for the /index page, then use this query:

  // `
  // query {
  //   featuredArticles: contentfulFeaturedArticlesLayout(
  //     node_locale: { eq: "de" }
  //     slug: { eq: null }
  //   ) {
  //     articles {
  //       ...Article
  //     }
  //   }
  // }
  // `

  const {
    articles: { nodes: articles },
  } = useStaticQuery(
    graphql`
      query {
        articles: allContentfulArticle(
          filter: {
            node_locale: { eq: "de" }
            audience: { name: { ne: null } }
            tags: { elemMatch: { name: { ne: null } } }
          }
          sort: { fields: date, order: DESC }
          limit: 3
        ) {
          nodes {
            ...Article
          }
        }
      }
    `
  )

  return (
    <Layout
      seo={{
        title: 'Finanzielle Flexibilität für Immobilienbesitzer',
        description:
          'Finanzielle Flexibilität für Immobilienbesitzer. Wir beraten Sie zur passenden Finanzierung, von Hypothek und Beleihung über Leibrente bis zum Teilverkauf.',
      }}
    >
      {/* Soft hyphenation is used to prevent forced breaking */}
      <Hero
        title={[
          'Finanzielle ',
          'Flexibilität für ',
          <>Immobilien&shy;besitzer.</>,
        ]}
        wordsToRotate={[
          'Teilverkaufslösung',
          'Immobilienbeleihung',
          'Immobilienfinanzierung',
          'Hypothek',
        ]}
        subtitleFirstPart="Wir finden die passende"
        subtitleLastPart={translate({
          AT: 'in Österreich.',
          DE: 'in Deutschland.',
          _: 'in Deutschland oder Österreich.',
        })}
      />
      <Benefits
        title="Liquidität für jeden Bedarf"
        items={[
          {
            title: 'Immobilienportfolio ausbauen',
            description:
              'Nutzen Sie Ihre Bestandsimmobilie, um die Anzahlung einer neuen Immobilie zu finanzieren',
            icon: '/icons/round-grow.svg',
          },
          {
            title: 'Liquidität für das Unerwartete',
            description:
              'Sorgen Sie vor, indem Sie einen Teil Ihres Vermögens liquide zur Verfügung haben',
            icon: '/icons/round-cover.svg',
          },
          {
            title: 'Umbau finanzieren',
            description:
              'Machen Sie beim Umbau keine Abstriche, die Sie später teuer ausgleichen müssen',
            icon: '/icons/round-finance.svg',
          },
          {
            title: 'Altersvorsorge',
            description:
              'Nehmen Sie rechtzeitig Liquidität auf, damit Sie die Vorteile eines günstigen Immobiliendarlehens genießen können',
            icon: '/icons/round-retirement.svg',
          },
        ]}
        ctaButtonID="Home--Benefits"
      />
      <Flow
        title="Das Bambus-Gerüst für Ihre Finanzierung"
        items={[
          {
            title: 'Bestandsaufnahme',
            description: 'Sie beschreiben Ihre aktuelle Situation',
          },
          {
            title: 'Produktvergleich',
            description:
              'Wir empfehlen eine auf Sie zugeschnittene Finanzierungslösung',
          },
          {
            title: 'Antrag',
            description:
              'Wir begleiten Sie bei der digitalen Erstellung eines Antrags',
          },
          {
            title: 'Prüfung',
            description:
              'Bei erfolgreicher Prüfung haben Sie Ihr Vermögen in Kürze liquide am Konto',
          },
        ]}
        ctaButtonID="Home--Flow"
      />
      <Testimonials showCTA ctaButtonID="Home--Testimonials" />
      <Comparison
        title="Die bessere Wahl"
        items={[
          {
            title: 'Hausbank',
            details: [
              'Begrenzte Auswahl',
              'Eingeschränkte Verwendung',
              'Kein Überblick',
              'Analoge Prozesse',
            ],
          },
          {
            primary: true,
            title: null,
            details: [
              'Eine Anfrage - viele Banken',
              'Kostenfreie Beratung',
              'Freie Mittelverwendung',
              'Einfache Prozesse',
            ],
          },
          {
            title: 'Verkauf',
            details: [
              'Verkaufspreis ungewiss',
              'Langwieriger Prozess',
              'Großer Aufwand',
              'Hohe Gebühren',
            ],
          },
        ]}
        ctaButtonID="Home--Comparison"
      />
      <Faq />
      <Summary
        title="Unsere Werte"
        items={[
          {
            icon: '/icons/bambus-clarity.svg',
            iconWidth: '77px',
            title: 'Übersichtlich',
            description:
              'Wir sorgen dafür, dass Sie stets den Überblick behalten.',
          },
          {
            icon: '/icons/bambus-transparency.svg',
            iconWidth: '79px',
            title: 'Transparent',
            description:
              'Jeder Schritt ist für Sie nachvollziehbar und verständlich – ohne Abschluss keine Bezahlung.',
          },
          {
            icon: '/icons/bambus-sustainability.svg',
            iconWidth: '82px',
            title: 'Nachhaltig',
            description:
              'Unsere Beratung konzentriert sich auf nachhaltige Finanzierung.',
          },
        ]}
        ctaButtonID="Home--Summary"
      />
      <Regulations country={countryCode} />
      <Blog title="Tauchen Sie in unseren Ratgeber ein" articles={articles} />
      <Map />
      <Partner />
    </Layout>
  )
}
export default Page
